<template>
  <div class="fixed z-10 inset-0 overflow-y-auto" v-if="show && !isUserAuth">
    <div
      class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <div
        class="fixed inset-0 transition-opacity"
        aria-hidden="true"
        @click="show = false"
      >
        <div class="absolute inset-0 bg-gray-400 opacity-75"></div>
      </div>
      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        >&#8203;</span
      >
      <div
        class="bg-gray-100 text-black dark:bg-dark dark:text-gray-100 inline-block align-bottom rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-3 sm:align-middle sm:max-w-lg sm:w-full"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
        v-if="type == 'login'"
      >
        <div class="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div class="sm:flex sm:items-start">
            <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <span
                class="inline-block uppercase text-blue-400 font-semibold tracking-widest"
                v-if="login.title"
                >{{ login.title }}</span
              >
              <h3
                class="mb-3 text-2xl font-bold font-heading text-blue-800"
                v-if="login.text"
              >
                {{ login.text }}
              </h3>
            </div>
          </div>
        </div>
        <div>
          <div class="bg-gray-100 px-4 py-3 sm:px-6 flex flex-col">
            <button
              class="flex items-center justify-center mb-4 py-5 border-2 border-blue-800 hover:bg-blue-50"
              @click="loginOauth('google')"
            >
              <span class="inline-block mr-4">
                <svg
                  role="img"
                  width="20"
                  height="16"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#4285F4"
                >
                  <title>Google icon</title>
                  <path
                    d="M12.48 10.92v3.28h7.84c-.24 1.84-.853 3.187-1.787 4.133-1.147 1.147-2.933 2.4-6.053 2.4-4.827 0-8.6-3.893-8.6-8.72s3.773-8.72 8.6-8.72c2.6 0 4.507 1.027 5.907 2.347l2.307-2.307C18.747 1.44 16.133 0 12.48 0 5.867 0 .307 5.387.307 12s5.56 12 12.173 12c3.573 0 6.267-1.173 8.373-3.36 2.16-2.16 2.84-5.213 2.84-7.667 0-.76-.053-1.467-.173-2.053H12.48z"
                  />
                </svg>
              </span>
              <span class="text-sm font-bold uppercase text-blue-800"
                >Sign in with Google</span
              >
            </button>
            <button
              class="flex items-center justify-center mb-4 py-5 border-2 border-blue-800 hover:bg-blue-50"
              @click="loginOauth('github')"
            >
              <span class="inline-block mr-4">
                <svg
                  role="img"
                  width="20"
                  height="16"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#181717"
                >
                  <title>GitHub icon</title>
                  <path
                    d="M12 .297c-6.63 0-12 5.373-12 12 0 5.303 3.438 9.8 8.205 11.385.6.113.82-.258.82-.577 0-.285-.01-1.04-.015-2.04-3.338.724-4.042-1.61-4.042-1.61C4.422 18.07 3.633 17.7 3.633 17.7c-1.087-.744.084-.729.084-.729 1.205.084 1.838 1.236 1.838 1.236 1.07 1.835 2.809 1.305 3.495.998.108-.776.417-1.305.76-1.605-2.665-.3-5.466-1.332-5.466-5.93 0-1.31.465-2.38 1.235-3.22-.135-.303-.54-1.523.105-3.176 0 0 1.005-.322 3.3 1.23.96-.267 1.98-.399 3-.405 1.02.006 2.04.138 3 .405 2.28-1.552 3.285-1.23 3.285-1.23.645 1.653.24 2.873.12 3.176.765.84 1.23 1.91 1.23 3.22 0 4.61-2.805 5.625-5.475 5.92.42.36.81 1.096.81 2.22 0 1.606-.015 2.896-.015 3.286 0 .315.21.69.825.57C20.565 22.092 24 17.592 24 12.297c0-6.627-5.373-12-12-12"
                  />
                </svg>
              </span>
              <span class="text-sm font-bold uppercase text-blue-800"
                >Sign in with Github</span
              >
            </button>
          </div>
        </div>
      </div>
      <div v-else>
        <button
          type="button"
          @click="show = false"
          class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { bus } from "../main";
import { mapGetters, mapActions } from "vuex";

export default {
  computed: {
    ...mapGetters(["getUser", "isUserAuth"]),
  },
  created() {
    bus.$on("showModal", (type) => {
      this.type = type;
      this.show = true;
    });
    bus.$on("closeModal", (type) => {
      console.log(type);
      this.show = false;
      this.$router.push("/profile");
    });
  },
  data: () => ({
    show: false,
    providers: ["github", "facebook", "google"],
    login: {
      title: "Start now",
      text: "Join Curri community",
    },
    type: "default",
  }),
  methods: {
    ...mapActions(["signInOauth"]),
    loginOauth(provider) {
      this.signInOauth(provider);
    },
  },
};
</script>
