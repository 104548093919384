<template>
  <Info class="hidden" />
</template>

<script>
import Info from '@/components/Info'


export default {
  components: {
    Info
  },
  name: "Home",
};
</script>
