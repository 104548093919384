<template>
  <nav
    class=" bg-white w-full flex fixed justify-between items-center mx-auto px-6 md:px-12 lg:px-16 h-12 shadow-md"
  >
    <div class="inline-flex">
      <router-link to="/">
        <div class="block">
          <p class="text-2xl font-bold font-heading text-blue-800">
            Curri
            <span
              class="hidden md:inline text-blue-400 font-semibold tracking-widest"
              >v0.0.1</span
            >
          </p>
        </div>
      </router-link>
    </div>
    <div class="flex-initial">
      <div class="flex justify-end items-center relative">
        <div class="flex mx-1 items-center">
          <button
            class="inline-block py-2 px-3 hover:bg-gray-200 focus:outline-none"
            @click="!isUserAuth ? loginModal() : goTo('/profile')"
          >
            <div
              class="flex items-center relative cursor-pointer whitespace-nowrap focus:outline-none"
            >
              <svg
                class="w-6 h-6 text-blue-800"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                ></path>
              </svg>
            </div>
          </button>
        </div>
        <div class="flex mx-1 items-center" v-if="isUserAuth">
          <button
            class="inline-block py-2 px-3 hover:bg-gray-200 focus:outline-none"
            @click="signOut"
          >
            <div
              class="flex items-center relative cursor-pointer whitespace-nowrap focus:outline-none"
            >
              <svg
                class="w-6 h-6 text-blue-800"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                ></path>
              </svg>
            </div>
          </button>
        </div>
        <div class="flex mx-1 items-center">
          <button
            class="inline-block py-2 px-3 hover:bg-gray-200 focus:outline-none"
          >
            <div
              class="flex items-center relative cursor-pointer whitespace-nowrap focus:outline-none"
            >
              <svg
                class="w-6 h-6 text-blue-800"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M4 6h16M4 12h16M4 18h16"
                ></path>
              </svg>
            </div>
          </button>
        </div>
      </div>
    </div>
  </nav>
  <!-- <div class="text-center my-2 md:mb-8">
    <router-link to="/">
      <button
        class="text-lg pb-2 mb-2 md:mb-0 mr-4 lg:mr-12 border-b-4 hover:border-gray-500"
      >
        Home
      </button>
    </router-link>
    <router-link to="/about">
      <button
        class="text-lg pb-2 mb-2 md:mb-0 mr-4 lg:mr-12 border-b-4 hover:border-gray-500"
      >
        About
      </button>
    </router-link>
    <button
      v-if="!isUserAuth"
      class="text-lg pb-2 mb-2 md:mb-0 mr-4 lg:mr-12 border-b-4 hover:border-gray-500"
      @click="loginModal"
    >
      Login
    </button>
    <button
      v-if="isUserAuth"
      class="text-lg pb-2 mb-2 md:mb-0 mr-4 lg:mr-12 border-b-4 hover:border-gray-500"
      @click="signOut"
    >
      Logout
    </button>
  </div> -->
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { bus } from "../main";

export default {
  name: "Top",
  computed: {
    ...mapGetters(["getUser", "isUserAuth"]),
  },
  methods: {
    ...mapActions(["signOutAction"]),
    signOut() {
      this.signOutAction();
      this.$router.push("/");
    },
    loginModal() {
      bus.$emit("showModal", "login");
    },
    goTo(route) {
      if (this.$router.currentRoute.path != route) {
        this.$router.push(route);
      }
    },
  },
};
</script>

<style></style>
