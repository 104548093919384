<template>
  <div id="app">
    <Top />
    <Modal />
    <hr class="mb-12">
    <section v-if="isUserAuth" class="section">
      <div class="columns">
        <div class="column is-half is-offset-one-quarter">
          Welcome {{ getUser.displayName }}
        </div>
      </div>
    </section>
    <router-view />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Modal from "@/components/Modal.vue";
import Top from "@/components/Top.vue";

export default {
  computed: {
    ...mapGetters(["getUser", "isUserAuth"]),
  },
  components: {
    Modal,
    Top,
  },
};
</script>
