import firebase from "firebase/app";
require("firebase/auth");
import { bus } from "../main";

const actions = {
  signUpAction({ commit }, payload) {
    firebase
      .auth()
      .createUserWithEmailAndPassword(payload.email, payload.password)
      .then((response) => {
        commit("setUser", response.user);
      })
      .catch((error) => {
        commit("setError", error.message);
      });
  },
  signInAction({ commit }, payload) {
    firebase
      .auth()
      .signInWithEmailAndPassword(payload.email, payload.password)
      .then((response) => {
        commit("setUser", response.user);
      })
      .catch((error) => {
        commit("setError", error.message);
      });
  },
  signInOauth({ commit }, payload) {
    let provider;
    if (payload == "github") {
      provider = new firebase.auth.GithubAuthProvider();
    } else if (payload == "google") {
      provider = new firebase.auth.GoogleAuthProvider();
    }

    firebase
      .auth()
      .signInWithPopup(provider)
      .then((response) => {
        commit("setUser", response.user);
        bus.$emit("closeModal", "login");
      })
      .catch((error) => {
        commit("setError", error.message);
      });
  },
  signOutAction({ commit }) {
    firebase
      .auth()
      .signOut()
      .then(() => {
        commit("setUser", null);
      })
      .catch((error) => {
        commit("setError", error.message);
      });
  },
};

export default actions;
